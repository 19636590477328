body {
  margin: 0;
  padding: 0;
  color: #333;
  box-sizing: border-box;
  background-color: #e1e1e1;
  font-family: "Helvetica Neue","Luxi Sans","DejaVu Sans",Tahoma,"Hiragino Sans GB",STHeiti,sans-serif!important;
}
html, body, #root {
  height: 100%;
  box-sizing: border-box;
}
